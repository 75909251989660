import { FC, memo } from 'react';

import { Box } from '@/common/components/Display';
import { Image as ImageComponent } from '@/common/components/Image';
import { useTheme } from '@/theme';

import classes from './_Cover.module.css';
import { useFrontPage, useFrontSite } from './Store';

export const SiteCover: FC = memo(() => {
  const theme = useTheme();
  const pageProperties = useFrontPage((x) => x.page?.properties);
  const siteProperties = useFrontSite((x) => x.site.properties);
  const properties = pageProperties ?? siteProperties;

  if (!properties.BillboardEnabled) {
    return null;
  }

  return (
    <Box
      className={classes.root}
      __vars={{ '--card-container-size': `${theme.other.cardContainerSize}px` }}
    >
      <ImageComponent
        image={properties.BillboardImage}
        isPriority
        style={{ height: 'auto' }}
      />
    </Box>
  );
});
