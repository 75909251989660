import { CSSProperties } from 'react';

import { ImageDataModel } from '@/common/models/ImageDataModel';
import { formatBackgroundAndFilter } from '@/common/utils/BackgroundFunctions';
import { theme } from '@/theme';

export interface StyledBackgroundProps {
  image?: ImageDataModel;
  color?: string;
  filterRgb?: string;
}

interface styleFnProps extends StyledBackgroundProps {
  theme: typeof theme;
}

export const backgroundStyle = (p: styleFnProps): CSSProperties => ({
  background: backgroundCssValue(p),
  backgroundColor: p.color ? p.color : p.theme.other.colors.white
});

export const backgroundCssValue = (
  p: Omit<styleFnProps, 'backgroundColor' | 'theme'>
) => `${formatBackgroundAndFilter({
  backgroundFilter: p.filterRgb,
  backgroundImageUrl: p.image?.url
})}
    no-repeat center center/cover fixed`;
