import { useEffect, useState } from 'react';
import { scroller } from 'react-scroll';

import { useScrollContainerContext } from '@/common/components/Scroll/ScrollContainerContext';
import { ToastPosition, useToaster } from '@/common/components/Toast';
import { useConstant, useConstantCallback } from '@/common/hooks/useConstant';
import { useId } from '@/common/hooks/useId';
import { Guid } from '@/common/models/Guid';
import { Page } from '@/common/models/pages/Page';
import { PageCard } from '@/common/models/pages/PageCard';

import { FrontPageStore } from './Store';

interface Options {
  siteId: Guid;
  /**
   * silence notifications for a period. any new content arriving
   */
  silenced: boolean;
  onClick?: (cards: PageCard[]) => void;
}
export const useNewContentNotifier = ({ silenced, onClick }: Options) => {
  const updatePageCards = useUpdatePageCardsData();

  const [updatedCards, setUpdatedCards] = useState<PageCard[]>([]);
  const id = useId();
  const toast = useToaster();
  const scrollContainerContext = useScrollContainerContext();

  const handleCleared = () => {
    if (updatedCards.length === 0) {
      return;
    }

    const timer = setTimeout(() => {
      scroller.scrollTo(updatedCards[0].id.toString(), {
        smooth: 'easeOutQuart',
        delay: 0,
        containerId: scrollContainerContext?.containerId ?? undefined,
        offset: -8,
        isDynamic: Boolean(scrollContainerContext?.containerId)
      });
      clearTimeout(timer);
    }, 500);

    setUpdatedCards(() => []);
  };

  useEffect(() => {
    if (updatedCards.length === 0 || silenced) {
      return;
    }

    toast.pill(`Load new content`, {
      id: id,
      position: ToastPosition.TopCenter,
      autoHide: false,
      actionIcon: 'refresh',
      onClosed: () => {
        handleCleared();
        updatePageCards(updatedCards);
        onClick?.(updatedCards);
      }
    });
  }, [updatedCards, silenced]);

  return useConstant(() => ({
    update: (cards: PageCard[]) => setUpdatedCards(cards)
  }));
};

const useUpdatePageCardsData = () => {
  return useConstantCallback((cards: PageCard[]) => {
    const page = FrontPageStore.getState().page;

    FrontPageStore.setState({
      page: new Page({
        ...page,
        cards: cards
      })
    });
  });
};
