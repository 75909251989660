export const formatBackgroundImageUrl = (backgroundImageUrl: string) =>
  `url(${backgroundImageUrl})`;

export const formatBackgroundFilter = (
  backgroundFilter: string
) => `linear-gradient(
      ${backgroundFilter},
      ${backgroundFilter}
    )`;

interface FormatProps {
  backgroundFilter?: string;
  backgroundImageUrl?: string;
}

/**
 * Combine a background image URL and a background filter RGBA value
 * to set a "background-image" value.
 *
 * @param props
 * @example
 *
 background-image: ${(p) =>
    formatBackgroundAndFilter({backgroundFilter: 'rgba(255,0,255,0.4)', backgroundImageUrl: 'https://www.timeitel.com/jagonaught.jpg'})};
 *
 */
export const formatBackgroundAndFilter = (props?: FormatProps) => {
  const backgroundParts = [];
  if (props?.backgroundFilter) {
    backgroundParts.push(formatBackgroundFilter(props?.backgroundFilter));
  }

  if (props?.backgroundImageUrl) {
    backgroundParts.push(formatBackgroundImageUrl(props?.backgroundImageUrl));
  }

  return backgroundParts.join(',');
};
