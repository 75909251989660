import { FC } from 'react';

import {
  FormDateMaskInput,
  FormDateMaskInputProps
} from '../Form/FormDateInput/FormDateMaskInput';
import classes from './EntryConditionDateInput.module.css';

export const EntryConditionDateInput: FC<FormDateMaskInputProps> = ({
  disabled: disabledProp,
  onChange,
  size = 'lg',
  w = 150,
  ...rest
}) => {
  const disabled = disabledProp || !onChange;
  return (
    <FormDateMaskInput
      disabled={disabled}
      onChange={onChange}
      size={size}
      w={w}
      classNames={{
        input: classes.input
      }}
      {...rest}
    />
  );
};
